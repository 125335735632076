import type { NormalizedCacheObject } from '@apollo/client'
import { ApolloProvider } from '@apollo/client'

import { useApiServerApolloClient } from '.'

type Props = {
  children: JSX.Element | JSX.Element[]
  initialApolloState?: NormalizedCacheObject
}

export default function ApolloClientProvider({ children, initialApolloState }: Props) {
  const apiServerApolloClient = useApiServerApolloClient(initialApolloState)

  return <ApolloProvider client={apiServerApolloClient}>{children}</ApolloProvider>
}
